.modal-slim-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1500;
}

.modal-slim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  max-width: 100%;
  z-index: 1501;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.4);
}
