@font-face {
  font-family: "Boge";
  src: local("Boge"), url("./fonts/Boge.otf") format("opentype");
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: local("Aktiv-Grotesk-Regular"), url("./fonts/AktivGroteskCorp-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: local("Aktiv-Grotesk-Bold"), url("./fonts/AktivGroteskCorp-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Futura Bold";
  src: local("FutureStd-Bold"), url("./fonts/FuturaStd-Bold.otf") format("opentype");
}
