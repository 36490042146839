@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts.scss";
@import "./modals.scss";

:root {
  --color-primary: #28edc0;
  --color-secondary: #00a3e9;
  --color-dark: #1a1a1a;
  --color-light: white;
  --navbar-height: 104px;
  --headerFont: Boge;
  --mainText: Aktiv Grotesk;
  --secondaryText: Futura Bold;
}

body {
  background: #1F2128;
  color: white;
  font-family: "Aktiv Grotesk";
  font-size: 16px;
  padding: 0;
  margin: 0;
  line-height: 1.75;
}

// Finer Tuning Styles

// Header Styles
img.logo {
  margin: 0 auto;
}

.nav-links {
  display: flex;
}

.nav-item a {
  padding-bottom: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 1rem;
}

.nav-link {
  text-decoration: none;
  transition: 0.3s all;
}

.nav-icon {
  display: none;
}

.nav-active {
  border-bottom: 1px solid #fff;
  font-weight: 700 !important;
}

// Header Responsive
@media screen and (max-width: 1135px) {
  .navbar {
    position: relative;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: left;
    width: 100%;
    top: 80px;
    left: -100%;
    transition: 0.5s all;
  }

  .nav-links.active {
    left: -1.5rem;
    background-color: #22252D;
    height: 105vh;
    z-index: 10;
    top: -3.75rem;
    padding-top: 6rem;
    padding-left: 2rem;
    width: 150%;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 20px;
  }

  .nav-item {
    padding: 22px 0;
  }

  .nav-icon {
    display: flex;
    color: #fff;
    font-size: 1.5rem;
    z-index: 100;
    position: absolute;
  }
}

@media screen and (max-width: 500px) {
  .nav-links.active {
    width: 250%;
  }
}

@media screen and (max-width: 300px) {
  .nav-links {
    left: -200%;
  }
}

// Needs Border Radius
.header .mint {
  border: 2px solid;
  border-radius: 90px;
  padding: 12px 24px;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #28EDC0, #00A3E9);
  text-decoration: none;
  font-size: 16px;
}

.header .wallet {
  border: 2px solid #777E90;
  border-radius: 90px;
  padding: 12px 24px;
  text-decoration: none;
  font-size: 16px;
}

// Homepage Styles
// VIP
.home-vip h1 {
  font-size: 55px;
  line-height: 55px;
  letter-spacing: -0.03em;
}

.home-vip h2 {
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -2%;
}

.home-vip img {
  width: 100%;
}

.home-vip .mint {
  background: #3772FF;
  border-radius: 12px;
  padding: 14px 30px;
}

.home-vip p {
  font-size: 16px;
  margin-bottom: 0;
}

.home-vip .learn {
  border: 2px solid #3772FF;
  border-radius: 12px;
  padding: 14px 30px;
  text-decoration: none;
}

.vip-promo {
  margin: 0 auto;
  max-width: 100%;
}

.vip-promo iframe {
  width: 100%;
  border-radius: 30px;
}

// VIP RESPONSIVE
@media screen and (max-width: 1279px) {
  .home-vip .mint {
    padding: 14px 13px;
  }

  .vip-promo .vp-video-wrapper {
    border-radius: 30px;
  }

  .home-vip .learn {
    padding: 14px 13px;
  }

  .home-vip h1 {
    font-size: 45px;
  }

  .home-vip h2 {
    font-size: 22px;
  }

  .home-vip p {
    font-size: 15px;
  }

  .vip-ctas .mint {
    width: 15%;
    text-align: center;
  }

  .vip-ctas .learn {
    width: 15%;
    text-align: center;
  }

  .vip-promo {
    margin: unset;
  }

  .player {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1023px) {
  .vip-ctas .mint {
    width: 20%;
  }

  .vip-ctas .learn {
    width: 20%;
  }

  .player {
    justify-content: center;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .vip-ctas a {
    max-width: 40%;
  }

  .vip-ctas {
    flex-direction: column;
  }

  .vip-ctas a {
    text-align: center;
    max-width: 60%;
  }

  .vip-ctas .mint {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 0 2rem;
  }

  .vip-ctas .mint {
    width: 35%;
  }

  .vip-ctas .learn {
    width: 35%;
  }
}

@media screen and (max-width: 450px) {
  .player {
    margin-top: -3rem;
  }
}

@media screen and (max-width: 400px) {
  .vip-ctas .mint {
    width: 50%;
  }

  .vip-ctas .learn {
    width: 50%;
  }
}

@media screen and (max-width: 350px) {
  .vip-ctas a {
    max-width: 70%;
  }

  .home-vip p {
    font-size: 13px;
  }

  .home-vip h1 {
    font-size: 45px;
  }

  .home-vip h2 {
    font-size: 20px;
  }

  .vip-ctas .mint {
    width: 100%;
  }

  .vip-ctas .learn {
    width: 100%;
  }
}

// Genesis
.genesis-slider {
  padding: 2rem 0;
}

.genesis-slider .title-slider h1:first-of-type {
  padding-top: 3rem;
}

.genesis-slider .slide-item {
  padding: 0 2rem;
}

.genesis-slider h1 {
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -0.03em;
}

.genesis-slider .overflow-title h1 {
  color: #4f525f;
  font-size: 200%;
  margin-bottom: 0;
  line-height: 30px;
  text-align: center;
}

.genesis-slider .slide-active {
  border-left: 1px solid #31343F;
  border-right: 1px solid #31343F;
}

.genesis-slider .content-slider .slide-item {
  padding-top: 5rem;
}

.genesis-slider .content-slider .slide-desc h2 {
  color: #FCFCF9;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
}

.genesis-slider .content-slider .slide-desc p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #7F8596;
}

.genesis-slider .content-slider .slide-number {
  margin-top: 3rem;
}

.genesis-slider .content-slider .slide-number h2 {
  font-weight: 700;
  font-size: 80px;
  line-height: 88px;
  letter-spacing: -0.03em;
  color: #FCFCF9;
}

.genesis-slider .content-slider .slide-number h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #7F8596;
  margin-left: 10px;
}

.genesis-slider .content-slider .slide-desc.right p {
  margin-bottom: 3rem;
}

.genesis-slider .content-slider .slide-desc.right a {
  border: 2px solid #3772FF;
  background: none;
  border-radius: 12px;
  padding: 16px 24px;
  color: #FFFFFF;
}

.genesis-slider .mobile-slide-cta a {
  border: 2px solid #3772FF;
  background: none;
  border-radius: 12px;
  padding: 16px 24px;
  color: #FFFFFF;
}

.genesis-slider .slick-dots li {
  margin: 0 1rem;
}

.genesis-slider .slick-dots li button:before {
  content: '';
  background-color: #7F8596;
  width: 30px;
  height: 8px;
  border-radius: 12px;
}

.genesis-slider .mobile-slider {
  display: none;
  padding: 2rem;
}

.genesis-slider .mobile-slider h1 {
  margin-bottom: 1rem;
}

.genesis-slider .mobile-slider p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #7F8596;
  margin-bottom: 2rem;
}

.genesis-slider .mobile-slider h2 {
  font-weight: 700;
  font-size: 70px;
  line-height: 88px;
  letter-spacing: -0.03em;
  color: #FCFCF9;
}

.genesis-slider .mobile-slider h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #7F8596;
  margin-left: 10px;
}

.genesis-slider .mobile-slider .slide-image {
  margin-top: 2rem;
}

.genesis-slider .slick-slide img {
  margin: 0 auto;
}

.genesis-slider .slick-dots {
  top: unset;
  bottom: -5rem;
}

@media screen and (max-width: 1175px) {
  .genesis-slider .mobile-slider {
    display: block;
  }

  .genesis-slider .desktop-slider {
    display: none;
  }

  .genesis-slider .slick-slide img {
    margin: unset;
  }

  .genesis-slider .slick-dots {
    bottom: -3rem;
    text-align: left;
    margin-left: -1rem;
  }

  .genesis-slider h1 {
    font-size: 45px;
  }
}

@media screen and (max-width: 600px) {
  .genesis-slider .slick-dots {
    bottom: -1rem;
  }
}

@media screen and (max-width: 350px) {
  .genesis-slider h1 {
    font-size: 35px;
  }

  .genesis-slider {
    margin: 0 -15px;
  }
}

// How
section.how {
  background: #22252D;
  padding-bottom: 0;
}

.how h1 {
  font-size: 50px;
  line-height: 72px;
  margin-bottom: 5rem;
}

.how .how-list .list:first-of-type {
  border-bottom: 1px solid #31343F;
  padding-top: 1rem;
}

.how .how-list .list.highlight {
  background-color: #23242d;
}

.how .how-list .list-container {
  padding: 1rem;
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
}

.how .how-list .list-container p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 25px;
  line-height: 44px;
  margin-left: 1rem;
}

.how .metafans-lineup {
  margin-top: 4rem;
}

// HOW RESPONSIVE
@media screen and (max-width: 1200px) {
  .how h1 {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 640px) {
  .how .how-list .list-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .how h1 {
    font-size: 40px;
  }
}

// Mint
section.mint {
  padding-top: 7rem;
}

.mint img.banner {
  border-radius: 30px;
  max-width: 100%;
}

section.mint h1 {
  font-size: 45px;
  line-height: 72px;
  margin-bottom: 5rem;
}

section.mint p {
  margin-left: 2rem;
  font-size: 18px;
  line-height: 28px;
  margin-top: -10px;
}

section.mint .mint-list img {
  width: 10rem;
}

section.mint .mint-list div.flex {
  border-right: 1px solid #31343F;
  padding-left: 2rem;
}

section.mint .mint-list div.flex:first-of-type {
  padding-left: 0;
}

section.mint .mint-list div.flex:last-of-type {
  border-right: none;
}

// MINT RESPONSIVE 
@media screen and (max-width: 1100px) {
  .mint .mint-list {
    flex-direction: column;
    align-items: start;
  }

  section.mint .mint-list img {
    width: 3rem;
  }

  section.mint .mint-list div.flex:first-of-type {
    padding-left: 2rem;
  }

  section.mint .mint-list div.flex {
    border-right: none;
    border-bottom: 1px solid #31343F;
    padding: 2rem;
  }

  section.mint .mint-list div.flex:last-of-type {
    border-bottom: none;
  }

  section.mint h1 {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 640px) {
  section.mint .mint-list img {
    width: 5rem;
  }

  section.mint .mint-list div.flex {
    padding: 2rem 0;
  }

  section.mint .mint-list div.flex:first-of-type {
    padding-left: 0;
  }

  section.mint h1 {
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .mint img.banner {
    height: 25vh;
    object-fit: cover;
  }

  section.mint h1 {
    font-size: 35px;
    line-height: 40px;
  }
}

@media screen and (max-width: 350px) {
  .genesis-slider .mobile-slide-cta a {
    padding: 10px 5px;
  }
}

// Countdown
section.countdown {
  padding: 10rem 5rem;
}

.countdown h1 {
  margin-bottom: 0;
  font-size: 45px;
  line-height: 50px;
  letter-spacing: -2%;
}

.countdown .countdown-info p.info {
  max-width: 85%;
  margin-top: 1rem;
}

.countdown .launch-countdown {
  background-color: #22252D;
  border: 2px solid #2C2F39;
  box-shadow: 0px 32px 64px -16px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  max-width: 85%;
  margin-top: 2rem;
}

.countdown .launch-countdown h2 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.countdown .launch-countdown p.launch-date {
  border: 2px solid #6abb7c47;
  border-radius: 6px;
  margin-left: 1rem;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.countdown .countdown-meter h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
}

.countdown .countdown-meter p {
  color: #7F8596;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
}

.countdown .countdown-meter div {
  border-right: 1px solid #31343F;
  padding: 5px 2rem;
}

.countdown .countdown-meter div:last-of-type {
  border: none;
}

.footer-count-notes {
  border-top: 1px solid #31343F;
  margin-top: 1.5rem;
  padding-top: 1rem;
}

.footer-count-notes p {
  font-size: 14px;
  line-height: 24px;
}

.footer-count-notes a {
  background: #3772FF;
  border-radius: 12px;
  padding: 16px 24px;
}

.footer-count-notes a p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
}

// COUNTDOWN RESPONSIVE
@media screen and (max-width: 1300px) {
  section.countdown {
    padding: 10rem 0;
  }
}

@media screen and (max-width: 1279px) {
  section.countdown {
    padding: 5rem 0;
  }
}

@media screen and (max-width: 1023px) {
  .countdown-container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .countdown-container img.secret {
    margin: 0 auto;
  }

  .countdown .launch-countdown {
    max-width: 100%;
  }

  .countdown .countdown-info p.info {
    max-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .countdown .countdown-meter div {
    padding: 5px 1rem;
  }

  .countdown .launch-countdown {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .countdown-info .countdown-meter {
    flex-direction: column;
  }

  .countdown .countdown-meter div {
    padding: 10px 0;
    width: 100%;
    border-bottom: 1px solid #31343F;
    border-right: none;
  }

  .countdown .countdown-meter div:last-of-type {
    border-bottom: none;
  }

  .footer-count-notes {
    margin-top: 0.5rem;
  }

  section.countdown {
    padding-top: 0;
  }
}

@media screen and (max-width: 375px) {
  .countdown a.discord {
    flex-direction: column;
  }

  .footer-count-notes a p {
    margin-left: 0;
  }
}

// Newbs
.newbs h1 {
  margin-bottom: 5rem;
}

.newbs-box {
  padding: 16px 16px 24px;
  border: 1px solid #353945;
  border-radius: 16px;
}

.newbs img {
  width: 100%;
  max-height: 400px;
  object-fit: fill;
  margin-bottom: 2rem;
}

.newbs .newbs-container {
  max-width: 70%;
  margin: 0 auto;
}

.newbs .newbs-container a.mint p,
.newbs .newbs-container a.nfts p {
  padding: 12px 16px;
  background-color: #3772FF;
  border-radius: 90px;
  font-size: 14px;
  font-weight: 700;
}

.newbs .newbs-container a.learn p {
  padding: 12px 16px;
  background: linear-gradient(90.21deg, #00A3E9 0.22%, #28EDC0 99.87%);
  border-radius: 90px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.newbs .newbs-container a {
  text-decoration: none;
}

// FAQs
.faqs {
  padding: 5rem 0;
}

.faqs h2 {
  font-size: 25px;
}

.faqs .accordion__button {
  padding: 2rem 0;
  border-bottom: 1px solid #31343F;
}

.faqs .accordion p,
.faqs .accordion ul {
  padding: 1rem 0;
}

.faqs .accordion ul {
  list-style: disc;
  margin-left: 1rem;
}

.faqs .accordion ul li {
  padding: 5px 0;
}

.faqs .faq-number {
  background: #E45F35;
  border-radius: 12px;
  color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 24px;
  font-weight: bold;
}

.faqs .accordion__button[aria-expanded='true'] img {
  transform: rotate(45deg);
  animation-duration: 5s;
}

// FAQ Responsive 
@media screen and (max-width: 650px) {
  .numbered-faq {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .accordion__button .open-img img {
    width: 2rem;
  }

  .accordion__button .open-img {
    justify-self: flex-end;
    text-align: right;
  }
}

// Signup
.home-signup {
  padding: 2rem;
}

.home-signup .signup-container .text-content {
  max-width: 50%;
  margin: 0 auto;
  padding: 3rem 0;
}

.home-signup .signup-container .social-icons div {
  margin: 0 10px;
}

.icon-assets img {
  position: absolute;
}

.icon-assets .coins {
  left: 0;
  top: 0;
  height: 100%;
  max-height: 300px;
}

.icon-assets .horse {
  right: -2rem;
  top: 0;
  height: 100%;
  max-height: 300px;
}

.home-signup .signup-box {
  padding: 5rem 0;
}

.home-signup.text-hidden p {
  display: none !important;
}

.signup-box p {
  text-align: left !important;
}

.signup-box div {
  background-color: transparent !important;
  padding-top: 0 !important;
}

.signup-box form {
  padding-top: 0 !important;
}

.klaviyo-form.klaviyo-form.klaviyo-form {
  padding: 0 !important;
}

.signup-box #text-signup div.needsclick {
  justify-content: start !important;
}

.home-signup input {
  background: rgb(97 103 125 / 50%) !important;
  border: 2px solid rgba(73, 78, 91, 0.15) !important;
  border-radius: 12px !important;
  padding: 0 20px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #FCFCF9 !important;
  height: 50px !important;
}

.home-signup div.phone-input {
  background: rgb(97 103 125 / 50%) !important;
  border: 2px solid rgba(73, 78, 91, 0.15) !important;
  border-radius: 12px !important;
  padding: 20px !important;
  min-width: 300px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #FCFCF9 !important;
}

.home-signup input::placeholder {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #FCFCF9 !important;
}

.home-signup label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FCFCF9;
  margin-bottom: 10px;
}

.home-signup button.needsclick {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: 2px solid rgb(219 228 255 / 30%) !important;
  border-radius: 12px !important;
  padding: 16px 32px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 15px !important;
}

.home-signup .signup-box [data-testid="form-row"].needsclick:last-child {
  margin-top: 1rem !important;
}

.home-signup #text-signup .needsclick.go4209603331.kl-private-reset-css-Xuajs1 button.needsclick.kl-private-reset-css-Xuajs1 {
  display: none !important;
}

.home-signup #text-signup .needsclick.go4209603331.kl-private-reset-css-Xuajs1 {
  border: none !important;
}

// SIGNUP RESPONSIVE 
@media screen and (max-width: 850px) {
  .icon-assets .coins {
    max-height: 150px;
  }

  .icon-assets .horse {
    max-height: 150px;
  }

  .home-signup .signup-container .text-content {
    max-width: 70%;
  }
}

@media screen and (max-width: 400px) {
  .home-signup .signup-box {
    width: 90% !important;
    margin: 0 auto;
  }

  .home-signup .signup-box [data-testid="form-row"].needsclick:last-child {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 1rem !important;

    [data-testid="form-component"]:first-child {
      flex: auto !important;
      padding: 0 !important;
    }

    [data-testid="form-component"]:last-child {
      margin: 10px 0 0 0 !important;
      padding: 0 0 40px !important;
    }
  }

  .home-signup .signup-box input {
    width: 100% !important;
  }

  .home-signup .signup-box button {
    width: 100% !important;
  }

  .icon-assets .coins {
    max-height: 100px;
  }

  .icon-assets .horse {
    max-height: 100px;
  }

  .home-signup .signup-container .social-icons div {
    margin: 0 5px;
  }
}

// Who We Are Styles
// About MF
.about-metafans h1 {
  font-size: 45px;
  line-height: 55px;
  letter-spacing: -0.03em;
}

.about-metafans h2 {
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -2%;
}

.about-metafans .about-stats {
  margin: 5rem 0;
}

.about-metafans .about-stats .stat-item {
  border-right: 1px solid #31343F;
  padding-left: 5rem;
}

.about-metafans .about-stats .stat-item:first-of-type {
  padding-left: 0;
}

.about-metafans .about-stats .stat-item:last-of-type {
  border-right: none;
}

.about-metafans .about-stats h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
}

.about-metafans p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.about-metafans .about-text {
  margin-bottom: 2rem;
}

// ABOUT MF RESPONSIVE 
@media screen and (max-width: 1279px) {
  .about-metafans .about-stats .stat-item {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .about-metafans .about-stats .stat-item {
    padding-left: 0;
    border-right: none;
    border-bottom: 1px solid #31343F;
    width: 100%;
    padding: 1rem 0;
  }

  .about-metafans .about-stats .stat-item:last-of-type {
    border-bottom: none;
  }

  .about-metafans .about-stats {
    margin-bottom: 3rem;
  }
}

// Team
section.about-team {
  background-color: #22252D;
  padding-top: 5rem;
}

.about-team .team-view {
  border-bottom: 2px solid #31343F;
}

.about-team .team-select {
  padding: 0 1rem;
}

.about-team .team-select img {
  padding-bottom: 2rem;
}

.about-team .team-select p {
  color: #7F8596;
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 5px;
}

.react-tabs__tab.react-tabs__tab--selected {
  background: transparent;
  border: none;
  color: #FCFCF9;
  border-radius: 0;
  border-bottom: 3px solid #9F73E6
}

.react-tabs__tab.react-tabs__tab--selected:focus:after {
  background: transparent !important;
}

.react-tabs__tab {
  padding: 0;
}

.about-team .team-list {
  margin-top: 3rem;
}

.about-team .team-list img {
  margin-bottom: 1rem;
}

.about-team .team-list img.headshot {
  width: 100%;
  height: 372px;
  object-fit: cover;
  border-radius: 16px;
  object-position: center;
}

.about-team .team-list p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
}

.about-team .team-list p.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

// Team Responsive Styles
@media screen and (min-width: 550px) and (max-width: 768px) {
  .about-team .team-list img.headshot {
    height: 500px;
  }
}

@media screen and (max-width: 400px) {
  .about-team .team-select p {
    text-align: center;
    margin-top: -5px;
  }
}

@media screen and (max-width: 300px) {
  .team-select img {
    display: none;
  }
}

// NFT & Utilities Page Styles
// Benefits
.utility-collection .benefits-header {
  margin-bottom: 4rem;
}

.utility-collection .benefits-header h2 {
  max-width: 75%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
}

.utility-collection .benefits-info p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
}

.utility-collection .benefits-info .benefits-list .single-benefit {
  border-bottom: 1px solid #31343F;
  padding: 1rem 0;
}

// Benefits Responsive
@media screen and (max-width: 768px) {
  .utility-collection .benefits-info .benefits-list .single-benefit {
    flex-direction: column;
  }
}

// Collections
section.the-collections {
  background: #22252D;
}

.the-collections .collections-list .single-collection {
  padding: 2rem 0;
}

.the-collections .collections-list .single-collection img {
  width: 100%;
  height: 380px;
  object-fit: cover;
  border-radius: 32px;
}

.the-collections .collections-list .single-collection h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
}

.the-collections .collections-list .single-collection p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
}

.the-collections .collections-list .single-collection p:last-of-type {
  margin-bottom: 3rem;
}

.the-collections .collections-list .single-collection a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 16px 32px;
  background: #3772FF;
  border-radius: 12px;
}

.the-collections .collection-stats {
  margin-bottom: 4rem !important;
}

.the-collections .collection-stats .stat-item p {
  margin-bottom: 0 !important;
}

.the-collections .collection-stats .stat-item h2 {
  margin-bottom: 0;
}

// Collections Responsive 
@media screen and (max-width: 500px) {
  .the-collections .collections-list .single-collection img {
    height: 250px;
  }

  .the-collections h1 {
    font-size: 45px;
    line-height: 45px;
  }
}

@media screen and (max-width: 300px) {
  .the-collections .collections-list .single-collection a {
    padding: 16px 10px;
  }
}

// Join
section.join-metafans {
  padding: 5rem 0;
}

.join-metafans img {
  height: fit-content;
}

.join-metafans h1 {
  line-height: 3.25rem;
}

.join-metafans h2:last-of-type {
  margin-bottom: 3rem;
}

.join-metafans a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 16px 32px;
  background: #3772FF;
  border-radius: 12px;
}

.join-metafans .slick-dots {
  display: block;
  position: absolute;
  right: 0;
  bottom: -5rem;
  width: auto;
  display: flex !important;
  align-items: start;
}

.join-metafans .slick-dots li {
  margin: 0 1rem;
}

.join-metafans .slick-dots li button:before {
  content: '';
  background-color: #7F8596;
  width: 30px;
  height: 8px;
  border-radius: 12px;
}

.slick-dots li.slick-active button:before {
  border: 3px solid #3772FF;
  background-color: transparent;
}

.utilities-vert-slider img {
  border-radius: 30px;
  max-height: 50vh;
  object-fit: contain;
}

// JOIN RESPONSIVE
@media screen and (max-width: 768px) {
  .join-metafans img {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 300px) {
  .join-metafans a {
    padding: 15px 10px;
  }
}

// Mint Page
section.mint-landing {
  min-height: 65vh;
  margin-top: 3rem;
}

.mint-landing h1 {
  line-height: 3.25rem;
}

.mint-landing h1:last-of-type {
  margin-bottom: 1rem;
}

.mint-landing p {
  max-width: 75%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.mint-landing .mint-ctas {
  margin: 3rem 0;
}

.mint-landing .connect button {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 16px 32px;
  background: #3772FF;
  border-radius: 12px;
}

.mint-landing .learn a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 16px 32px;
  background: transparent;
  border: 2px solid #3772FF;
  border-radius: 12px;
}

// MINT PAGE RESPONSIVE
@media screen and (max-width: 640px) {
  .mint-landing .learn {
    margin-top: 3rem;
  }

  .mint-landing h1 {
    font-size: 35px;
    line-height: 45px;
  }
}

// Privacy Policy
.policy-text p {
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .privacy h1 {
    font-size: 35px;
  }
}

// Terms of Service
.terms-conditions p {
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .terms-conditions h1 {
    font-size: 35px;
  }
}

// Affiliate Page Styles
.affiliate a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 16px 32px;
  background: #3772FF;
  border-radius: 12px;
}

.affiliate .sign-up {
  margin-top: 3rem;
}

.affiliate p {
  max-width: 80%;
  margin: 0 auto;
}

.affiliate img {
  border-radius: 30px;
  margin-bottom: 3rem;
}

// Affiliate Responsive
@media screen and (max-width: 768px) {
  .affiliate h1 {
    font-size: 45px;
    line-height: 45px;
  }
}


// Footer Styles
section.footer {
  padding: 2rem;
}

.footer .footer-container {
  padding-bottom: 2rem;
}

.footer .footer-nav ul {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  padding: 0;
}

.footer .footer-nav a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #7F8596;
  padding-right: 1rem;
  padding: 0 1rem;
}

.footer .footer-nav a:first-of-type {
  padding-left: 5px;
}

.footer .footer-icons {
  margin-right: 1rem;
}

.footer .footer-icons div {
  padding: 0 10px;
}

.footer .mint {
  border: 2px solid;
  border-radius: 90px;
  padding: 12px 24px;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #28EDC0, #00A3E9);
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: #FCFCFD;
  margin-right: 1rem;
}

.footer .wallet {
  border: 2px solid #777E90;
  border-radius: 90px;
  padding: 12px 24px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: #FCFCFD;
}

.footer .footer-copyright {
  border-top: 1px solid #31343F;
  padding-top: 1rem;
}

.footer .footer-copyright .mini-nav div {
  margin: 0 10px;
}

.footer .footer-copyright .mini-nav a {
  text-decoration: none;
}

.footer .footer-copyright p {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
}

// FOOTER RESPONSIVE
@media screen and (max-width: 1024px) {
  .footer .footer-nav a {
    padding: 10px 0;
  }

  .footer .footer-nav a:first-of-type {
    padding-left: 0;
  }

  .footer .footer-icons div {
    margin-bottom: 1rem;
  }

  .footer .mint,
  .footer .wallet {
    margin-bottom: 1rem;
  }
}
